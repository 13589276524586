import { FC, useMemo } from 'react';
import { demoAssessment } from '../../demoAssessment';
import { IAnswersTourMaskProps } from './answers-tour-mask.models';
import { useStyles } from './use-styles';

export const AnswersTourMask: FC<IAnswersTourMaskProps> = ({ answersRef, selector = 'test-step-mask' }) => {
  const classes = useStyles();
  const { questionListByOneRect } = demoAssessment;

  const maskTourStyle = useMemo(() => {
    if (!answersRef.current) {
      return null;
    }

    const questionWrapperHeight = questionListByOneRect?.height;
    const answerHeight = answersRef.current.getBoundingClientRect().height;

    if (answerHeight < questionWrapperHeight) {
      return null;
    }

    return {
      maxHeight: questionWrapperHeight ? `${questionWrapperHeight}px` : '100%',
    };
  }, [questionListByOneRect?.height, answersRef.current]);

  return <div data-tour={selector} className={classes.answersMaskTour} style={maskTourStyle} />;
};
