import { StepType } from '@reactour/tour';
import { badgeArrowStyles } from '../badgeArrowStyles';
import { getRelativePositionsElements } from '../utils';

export const INTRODUCTION_STEPS: StepType[] = [
  {
    selector: '[data-tour="introduction-step-1"]',
    position: 'bottom',
    styles: {
      badge: () => badgeArrowStyles.bottom,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Название оценочной сессии, квалификация и аннотация к оценочной сессии
        </div>
        <div className="text-slate-600">
          Здесь вы увидите название квалификации, название оценочной сессии, которую будете проходить, и ее краткое
          описание
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-2"]',
    position: (position) => [position.x - 25 - position.width, position.y - 10],
    styles: {
      badge: () => badgeArrowStyles.left,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Трудоемкость и срок реализации</div>
        <div className="text-slate-600">
          Здесь обозначаются сроки реализации и трудоемкость программы, по которой вы проходите оценочную сессию. Если
          оценка не является частью образовательной программы, этого блока не будет в оценочной сессии
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-3"]',
    position: (position) => {
      if (window.innerWidth <= 1490) {
        return [position.x - 25 - position.width, position.y + 12 - position.width];
      }

      return 'left';
    },
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.leftBottom;
        }
        if (window.innerHeight <= 804) {
          return badgeArrowStyles.secondLeftBottom;
        }

        return badgeArrowStyles.left;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Блок с данными о времени прохождения оценочной сессии
        </div>
        <div className="text-slate-600">
          Перед началом сверьтесь со временем, необходимым на оценку. Если сейчас вам неудобно проходить оценку,
          выберите другое удобное время
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-4"]',
    position: 'bottom',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }
        if (window.innerHeight <= 868) {
          return badgeArrowStyles.top;
        }

        return badgeArrowStyles.bottom;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Блок с компетенциями</div>
        <div className="text-slate-600">Здесь указан перечень компетенций, по которым проводится оценочная сессия</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="never"]',
    position: 'center',
    padding: { mask: 0 },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Начало оценки</div>
        <div className="text-slate-600">Далее будет показано, как начать оценочную сессию</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-5"]',
    position: 'top',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }

        return badgeArrowStyles.top;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Этапы оценки</div>
        <div className="text-slate-600">
          У оценочной сессии может быть один или несколько этапов. Количество, обозначенное в сессии, не будет
          изменяться
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-6"]',
    position: 'top',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }

        return badgeArrowStyles.top;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Даты доступности оценочной сессии</div>
        <div className="text-slate-600">
          У каждого этапа обозначен временной период, в который вам будет доступна оценочная сессия
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-6-2"]',
    position: 'top',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }

        return badgeArrowStyles.top;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Попытки</div>
        <div className="text-slate-600">
          Количество попыток старта оценки. Попытки доступны пока вы не завершите оценку. После пройти оценку заново не
          будет возможности
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-7"]',
    position: (position) => [position.x - 25 - position.width, position.top - position.height + 75],
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.leftBottom;
        }

        return badgeArrowStyles.leftBottom;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Значок прокторинга</div>
        <div className="text-slate-600">
          Если в этапе оценочной сессии используется прокторинг, вы увидите значок в виде силуэта человека в рамке
        </div>
        <div className="text-xl text-slate-800 mb-2 font-semibold">Как пройти оценку с прокторингом?</div>
        <div className="text-slate-600">
          Зайдите в свой профиль и во вкладке «Документы» сделайте три эталонных фото, а в личном кабинете распечатайте
          согласие на обработку персональных данных, подпишите собственноручно и передайте это согласие организатору
          оценки
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-8"]',
    position: 'right',
    styles: {
      badge: () => {
        const relativePos = getRelativePositionsElements('[data-tour="introduction-step-8"]', '.reactour__popover');

        if (relativePos.left) {
          return badgeArrowStyles.left;
        }
        if (relativePos.right) {
          return badgeArrowStyles.rightUpperTop;
        }

        return badgeArrowStyles.top;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Чтобы начать оценку, нажмите кнопку «Начать оценку»
        </div>
      </div>
    ),
  },
];
