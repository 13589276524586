import { StepType } from '@reactour/tour';
import { badgeArrowStyles } from '../badgeArrowStyles';

export const MAIN_PAGE_CONTINUE_STEPS: StepType[] = [
  {
    selector: '[data-tour="introduction-step-6-1"]',
    position: 'top',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }

        return badgeArrowStyles.top;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Таймер оставшегося времени</div>
        <div className="text-slate-600">
          Отсчитывает время до завершения оценки. Если оценка не завершена до конца времени таймера - попытка сгорает
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="introduction-step-8"]',
    position: 'bottom',
    styles: {
      badge: () => {
        if (window.innerWidth <= 1490) {
          return badgeArrowStyles.top;
        }
        if (window.innerHeight <= 880) {
          return badgeArrowStyles.top;
        }
        if (window.innerHeight <= 906) {
          return badgeArrowStyles.rightUpperTop;
        }

        return badgeArrowStyles.bottom;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Чтобы продолжить оценку, нажмите кнопку «Продолжить»
        </div>
      </div>
    ),
  },
];
