import { Outlet } from 'react-router-dom';
import { TourProvider } from '@reactour/tour';
import { getMaxWidth } from '../../../shared/screen-utils';
import { BadgeWithArrow } from './BadgeWithArrow';
import { INTRODUCTION_STEPS } from './steps';
import { TourNavigation } from './TourNavigation';

const customStyles = {
  popover: (base, state) => {
    const { position } = state;

    let additionalStyles = {};

    if (position === 'bottom') {
      additionalStyles = {
        left: '15px',
      };
    }

    if (position === 'top') {
      additionalStyles = {
        left: '15px',
      };
    }

    if (position === 'left') {
      additionalStyles = {
        marginTop: '15px',
      };
    }
    if (position === 'right') {
      additionalStyles = {
        marginTop: '15px',
      };
    }

    return {
      ...base,
      ...additionalStyles,
      maxWidth: getMaxWidth(),
      backgroundColor: '#fff',
      borderRadius: '12px',
      boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
    };
  },
  mask: (base) => ({
    ...base,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
  }),
  maskArea: (base) => ({
    ...base,
    rx: '12px',
  }),
};

export const Tour = () => (
  <TourProvider
    scrollSmooth
    steps={INTRODUCTION_STEPS}
    styles={customStyles}
    onClickMask={() => {}}
    showCloseButton={false}
    components={{
      Badge: () => <BadgeWithArrow />,
      Navigation: TourNavigation,
    }}
    defaultOpen
    padding={{ popover: 25 }}
  >
    <Outlet />
  </TourProvider>
);
