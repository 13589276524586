import { StepType } from '@reactour/tour';
import { badgeArrowStyles } from '../badgeArrowStyles';

export const TEST_STEPS: StepType[] = [
  {
    selector: '[data-tour="test-step-1"]',
    position: 'left',
    styles: {
      badge: () => badgeArrowStyles.left,
    },

    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Вид отображения вопросов: списком</div>
        <div className="text-slate-600">
          В оценочной сессии вопросы могут предъявляться списком — все вопросы на одном экране, пролистываются
          скроллингом
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-2"]',
    position: 'left',
    styles: {
      badge: () => badgeArrowStyles.left,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Вид отображения: по одному</div>
        <div className="text-slate-600">Или по одному — каждый вопрос выводится отдельно</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-3"]',
    position: 'bottom',
    styles: {
      badge: () => badgeArrowStyles.bottom,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Переход к нужному вопросу через меню наверху</div>
        <div className="text-slate-600">
          Во время прохождения оценочной сессии вы можете перемещаться от вопроса к вопросу, кликая на номер вопроса
        </div>
      </div>
    ),
  },
  {
    selector: '#test-step-4',
    position: 'top',
    styles: {
      badge: () => badgeArrowStyles.top,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Переход к следующему вопросу через кнопку «Далее»
        </div>
        <div className="text-slate-600">
          Или нажимая на кнопку «Далее», если вопросы выводятся по одному. Отвеченные вопросы подчеркиваются снизу
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-5"]',
    position: 'left',
    styles: {
      badge: () => badgeArrowStyles.left,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Прогресс бар</div>
        <div className="text-slate-600">Каждый ответ заполняет шкалу прогресса</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-6"]',
    position: (position) => {
      if (window.innerHeight <= 900) {
        return [position.x - 25 - position.width, position.top - position.height + 61];
      }

      return 'left';
    },
    styles: {
      badge: () => {
        if (window.innerHeight <= 900) {
          return badgeArrowStyles.leftBottom;
        }

        return badgeArrowStyles.left;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Таймер</div>
        <div className="text-slate-600">Отсчитывает время, которое у вас осталось на завершение оценки</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-9"]',
    position: (position) => {
      if (window.innerHeight <= 750) {
        return [position.x - 25 - position.width, position.top - position.height + 85];
      }

      return 'left';
    },
    styles: {
      badge: () => {
        if (window.innerHeight <= 750) {
          return badgeArrowStyles.leftBottom;
        }

        return badgeArrowStyles.left;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Закрыть</div>
        <div className="text-slate-600">
          Позволяет вернуться к главному экрану оценки. Внимание: Не останавливает таймер и не завершает оценку
        </div>
      </div>
    ),
  },
];
