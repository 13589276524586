import { StepType } from '@reactour/tour';
import { badgeArrowStyles } from '../badgeArrowStyles';

export const MAIN_PAGE_FINISH_STEPS: StepType[] = [
  {
    selector: '[data-tour="main-page-answer-step-1"]',
    position: 'top',
    styles: {
      badge: () => badgeArrowStyles.top,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Кнопка «Смотреть результаты»</div>
        <div className="text-slate-600">
          Результаты оценки рассчитываются сразу и узнать их можно, нажав на кнопку «Смотреть результаты»
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour-disabled="main-page-answer-step-1"]',
    position: 'top',
    styles: {
      badge: () => badgeArrowStyles.top,
    },
    content: (
      <div>
        <div className="text-slate-600">Нажмите на кнопку «Смотреть результаты»</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="main-page-answer-step-2"]',
    position: (position) => [position.x - 25 - position.width, (position.windowHeight - position.height) / 2],
    styles: {
      badge: () => badgeArrowStyles.leftCenter,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Раздел «Компетенции»</div>
        <div className="text-slate-600">
          Результаты показываются двумя способами. Первый - таблица.
          <div className="my-2">В ней обозначается:</div>
          <ul className="list-disc list-inside marker:text-primary-darkBlue">
            <li>название компетенции</li>
            <li>название этапа оценки (столбец «Оценка»)</li>
            <li>выявленный в ходе оценки уровень сформированности компетенции (столбец «Уровень»)</li>
            <li>
              интерпретация выявленного уровня, т.е. обозначение, каким образом компетенция проявляется (столбец
              «описание»)
            </li>
          </ul>
          Если в оценочной сессии было несколько компетенций, такой набор информации будет представлен для каждой
          компетенции
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="main-page-answer-step-2"]',
    position: 'left',
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Кнопка «Диаграмма»</div>
        <div className="text-slate-600">
          Чтобы увидеть свои результаты в виде диаграммы, нажмите на кнопку «Диаграмма»
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="main-page-answer-step-3"]',
    position: 'bottom',
    styles: {
      badge: () => badgeArrowStyles.bottom,
    },
    content: (
      <div>
        <div className="text-slate-600">Нажмите на кнопку «Диаграмма»</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="main-page-answer-step-2"]',
    position: (position) => [position.x - 25 - position.width, position.y],
    styles: {
      badge: () => badgeArrowStyles.leftCenter,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Всплывающий блок с целевой компетенцией и достигнутой
        </div>
        <ul className="text-slate-600">
          <li>При наведении на один из лучей диаграммы во всплывающем окне появится:</li>
          <li>- название компетенции</li>
          <li>- выявленный в ходе оценки уровень сформированности компетенции</li>
          <li>- уровень данной компетенции, который был обозначен как целевой, т.е. рекомендуемый к достижению</li>
        </ul>
      </div>
    ),
  },
  {
    selector: '[data-tour="main-page-answer-step-2"]',
    position: (position) => [position.right + 25, position.y],
    styles: {
      popover: (props, state) => ({
        ...state,
        ...props,
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        maxWidth: '250px',
      }),
      badge: () => badgeArrowStyles.rightSlightlyLower,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Кнопка закрытия окна</div>
        <div className="text-slate-600">Ознакомившись с результатами, закройте окно</div>
      </div>
    ),
  },
];
