import { StepType } from '@reactour/tour';
import { badgeArrowStyles } from '../badgeArrowStyles';

export const ANSWERS_PAGE_STEPS: StepType[] = [
  {
    selector: '[data-tour="answer-step-1"]',
    position: 'left',
    styles: {
      popover: (props, state) => ({
        ...state,
        ...props,
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        maxWidth: '290px',
      }),
      badge: () => badgeArrowStyles.left,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Раздел «Ответы»</div>
        <div className="text-slate-600">
          После того как вы завершите оценочную сессию, вы увидите свои ответы на вопросы без отметки о правильности.{' '}
        </div>
        <div className="text-slate-600">
          Чтобы узнать результаты оценки, нажмите на кнопку «Назад к оценочной сессии»
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tour="answer-step-2"]',
    position: 'bottom',
    styles: {
      badge: () => badgeArrowStyles.bottom,
    },
    content: (
      <div>
        <div className="text-slate-600">Нажмите на кнопку «Назад к оценочной сессии»</div>
      </div>
    ),
  },
];
