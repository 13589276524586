import { StepType } from '@reactour/tour';
import { QuestionType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { badgeArrowStyles } from '../badgeArrowStyles';

interface IQuestionAnswerSelectProps {
  questionType: QuestionType;
  activeNextStep?: boolean;
  selector?: string;
}

const getQuestionAnswerSelectStep = ({
  questionType,
  activeNextStep = true,
  selector,
}: IQuestionAnswerSelectProps): StepType[] => [
  {
    selector: selector ?? `[data-tour="test-step-${questionType}"]`,
    position: (position) => {
      if (window.innerHeight <= 840) {
        return [position.x - 25 - position.width, window.innerHeight - position.height - 20];
      }

      return [position.x - 25 - position.width, position.y - 10];
    },
    styles: {
      badge: () => badgeArrowStyles.left,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-10 font-semibold">Выберите ответ(ы)</div>
      </div>
    ),
  },
  activeNextStep && {
    selector: '#test-step-4',
    position: 'top',
    styles: {
      badge: () => badgeArrowStyles.top,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-10 font-semibold">После ответа на вопрос нажмите «Далее»</div>
      </div>
    ),
  },
];

export const QUESTION_STEPS: Record<string, StepType[]> = {
  [QuestionType.one]: [
    {
      padding: { mask: 100000 },
      position: () => [0, 200],
      selector: `[data-question-tour="step-${QuestionType.one}"]`,
      action: (elem) => {
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      },
      content: (
        <div>
          <div className="text-xl text-slate-900 mb-2 font-semibold">Тип ответа на вопрос: один из списка</div>
          <div className="text-slate-600">
            В оценочной сессии есть несколько вариантов заданий. Первый из них — тест с одним ответом. Выберите один из
            списка
          </div>
        </div>
      ),
    },
    ...getQuestionAnswerSelectStep({ questionType: QuestionType.one }),
  ],
  [QuestionType.plural]: [
    {
      padding: { mask: 100000 },
      position: () => [0, 200],
      selector: `[data-question-tour="step-${QuestionType.plural}"]`,
      action: (elem) => {
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      },
      content: (
        <div>
          <div className="text-xl text-slate-900 mb-2 font-semibold">Тип ответа на вопрос: несколько из списка</div>
          <div className="text-slate-600">
            Следующий тип заданий — тест с несколькими верными ответами. Выберите все верные ответы
          </div>
        </div>
      ),
    },
    ...getQuestionAnswerSelectStep({ questionType: QuestionType.plural }),
  ],
  [QuestionType.case]: [
    {
      padding: { mask: 100000 },
      position: () => [0, 200],
      selector: `[data-question-tour="step-${QuestionType.case}"]`,
      action: (elem) => {
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      },
      content: (
        <div>
          <div className="text-xl text-slate-900 mb-2 font-semibold">Кейс-тест</div>
          <div className="text-slate-600">
            В оценочной сессии есть и практические задания. Одно из них — кейс-тест. Ознакомьтесь с условиями задачи и
            выберите один самый подходящий вариант ответа
          </div>
        </div>
      ),
    },
    ...getQuestionAnswerSelectStep({
      questionType: QuestionType.case,
      selector: `[data-tour="test-step-mask-${QuestionType.case}"]`,
    }),
  ],
  [QuestionType.matrix]: [
    {
      padding: { mask: 100000 },
      position: () => [0, 200],
      selector: `[data-question-tour="step-${QuestionType.matrix}"]`,
      action: (elem) => {
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      },
      content: (
        <div>
          <div className="text-xl text-slate-900 mb-2 font-semibold">Кейс-последовательность</div>
          <div className="text-slate-600">
            Следующий тип кейсов — это кейс-последовательность, он предполагает установление правильной очередности
            действий, операций или явлений. Прочитайте задания и начните устанавливать последовательность. Если вы
            поняли, что устанавливаете не верную последовательность, сбросьте ответ и начните заново
          </div>
        </div>
      ),
    },
    ...getQuestionAnswerSelectStep({
      questionType: QuestionType.matrix,
      selector: `[data-tour="test-step-mask-${QuestionType.matrix}"]`,
    }),
  ],
  [QuestionType.tree]: [
    {
      padding: { mask: 100000 },
      position: () => [0, 200],
      selector: `[data-question-tour="step-${QuestionType.tree}"]`,
      action: (elem) => {
        if (elem) {
          elem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
        }
      },
      content: (
        <div>
          <div className="text-xl text-slate-900 mb-2 font-semibold">Кейс: дерево вопросов</div>
          <div className="text-slate-600">
            Следующий тип кейса — дерево вопросов. Он основан на правильном выборе вопросов, а не ответов, как в
            классических заданиях. Прочтите описание и задайте дополнительные вопросы для решения этого кейса
          </div>
        </div>
      ),
    },
    ...getQuestionAnswerSelectStep({ questionType: QuestionType.tree, activeNextStep: false }),
  ],
};
