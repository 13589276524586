import { StepType } from '@reactour/tour';
import { badgeArrowStyles } from '../badgeArrowStyles';

export const TEST_FINISH_STEPS: StepType[] = [
  {
    selector: '[data-tour="test-step-10"]',
    position: (position) => {
      if (window.innerHeight <= 900) {
        return [position.x - 25 - position.width, position.y + 77 - position.width];
      }

      return 'left';
    },
    styles: {
      badge: () => {
        if (window.innerHeight <= 900) {
          return badgeArrowStyles.leftBottom;
        }

        return badgeArrowStyles.left;
      },
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Кнопка «Завершить»</div>
        <div className="text-slate-600">После того как все задания решены, обязательно нажмите кнопку «Завершить»</div>
      </div>
    ),
  },
  {
    selector: '[data-tour="test-step-11"]',
    padding: { popover: 30, mask: 10000 },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">
          Нажмите завершить, чтобы перейти к следующему этапу демо-тестирования
        </div>
      </div>
    ),
  },
];
