import { StepType } from '@reactour/tour';
import { badgeArrowStyles } from '../badgeArrowStyles';

export const TIME_LEFT_FINISH_STEP: StepType[] = [
  {
    selector: '[data-tour="finish-step"]',
    position: (position) => [position.left - 9, position.bottom + 20],
    styles: {
      popover: (props, state) => ({
        ...state,
        ...props,
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        maxWidth: '360px',
      }),
      badge: () => badgeArrowStyles.bottom,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Время истекло</div>
        <div className="text-slate-600">
          Закончилось время, отведенное на прохождение демонстрационной оценочной сессии. Нажмите на кнопку "Назад к
          списку оценочных сессий", чтобы иметь возможность начать новую демонстрационную оценочную сессию.
        </div>
      </div>
    ),
  },
];
