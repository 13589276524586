import { StepType } from '@reactour/tour';
import { badgeArrowStyles } from '../badgeArrowStyles';

export const FINISH_STEP: StepType[] = [
  {
    selector: '[data-tour="finish-step"]',
    position: (position) => [position.left - 9, position.bottom + 20],
    styles: {
      popover: (props, state) => ({
        ...state,
        ...props,
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
        maxWidth: '360px',
      }),
      badge: () => badgeArrowStyles.bottom,
    },
    content: (
      <div>
        <div className="text-xl text-slate-900 mb-2 font-semibold">Поздравляем!</div>
        <div className="text-slate-600">
          Вы завершили демонстрационную оценочную сессию. Нажмите на кнопку “Назад к списку оценочных сессий“, чтобы
          применить полученные знания в реальных оценочных сессиях.
        </div>
      </div>
    ),
  },
];
