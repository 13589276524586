import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  question: {
    color: theme.palette.gloomyBlue.main,
    font: {
      size: 18,
      weight: 500,
    },
    lineHeight: '24px',
  },
  description: {
    marginTop: 8,
    color: theme.palette.secondaryText.main,
  },
  answerInfo: {
    display: 'flex',
    alignItems: 'start',
    gap: '12px',
  },
  answerVariant: {
    padding: '3px 7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.lines.main,
    opacity: 0.5,
    borderRadius: '8px',
  },
  answerContent: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    paddingTop: '2px',
  },
  answer: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.gloomyBlue.main,
  },
  answerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    position: 'relative',
  },
  title: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.gloomyBlue.main,
  },
  btnDelete: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  sequenceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '32px',
  },
  sequenceAction: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sequence: {
    width: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  selectedSequenceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  selectedSequence: {
    width: '48px',
    height: '48px',
    backgroundColor: 'rgba(0, 113, 206, 0.07)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  answerSequenceWrapper: {
    width: 'max-content',
    display: 'flex',
    border: `1px solid ${theme.palette.lines.main}`,
    borderRadius: '8px',
  },
  numberSequenceWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  sequenceSelected: {
    width: 'max-content',
    border: '1px solid #99C6EB',
    borderRadius: '8px',
    display: 'flex',
  },
  answerSequence: {
    display: 'flex',
    color: theme.palette.secondaryText.main,
    '& > span': {
      width: '60px',
      height: '48px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.black.main,
        backgroundColor: theme.palette.gray.main,
      },
    },
  },
  active: {
    color: theme.palette.black.main,
    backgroundColor: theme.palette.gray.main,
  },
  numberSequence: {
    display: 'flex',
    border: `1px solid ${theme.palette.lines.main}`,
    borderRadius: '4px',
    '& > span': {
      width: '48px',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));
