import { createUseStyles } from 'react-jss';


export const useStyles = createUseStyles(() => ({
  answersMaskTour: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -999999,
  },
}));
