type RelativePosResult = {
  top: boolean;
  bottom: boolean;
  right: boolean;
  left: boolean;
};

const getElementPositions = (selectors: string): DOMRect | null => {
  const element = document.querySelector(selectors);
  if (!element) {
    return null;
  }

  return element.getBoundingClientRect();
};

export const getRelativePositionsElements = (
  firstSelector: string,
  secondSelector: string,
): RelativePosResult | null => {
  const alphaPos = getElementPositions(firstSelector);
  const betaPos = getElementPositions(secondSelector);

  if (!alphaPos || !betaPos) {
    return null;
  }

  const relativePos: RelativePosResult = {
    top: false,
    bottom: false,
    right: false,
    left: false,
  };

  if (alphaPos.bottom <= betaPos.top) {
    relativePos.bottom = true;
  }

  if (alphaPos.top >= betaPos.bottom) {
    relativePos.top = true;
  }

  if (alphaPos.right <= betaPos.left) {
    relativePos.right = true;
  }

  if (alphaPos.left >= betaPos.right) {
    relativePos.left = true;
  }

  return relativePos;
};
